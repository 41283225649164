/* eslint-disable */
import { bwThemeboy } from '../theme/images'
import { 
    greenThemeLight, greenThemeDark, bwThemeLight, bwThemeDark, blueThemeLight, blueThemeDark, redThemeLight, redThemeDark, orangeThemeLight, orangeThemeDark, purpleThemeLight, purpleThemeDark, pinkThemeLight, pinkThemeDark, yellowThemeLight, yellowThemeDark,tealThemeLight
} from '../theme/theme'


export const themeData = {
    theme: bwThemeDark
}


// Choose theme from above